<template>
  <div>
    <dx-modal title="Guia de consulta"
              id="modal-consultation-account" size="lg"
              :value="show" @input="close(false)">
      <div class="loading loading-lg" v-if="loading" />
      <div class="insurance-account" v-else>
        <template v-if="finished">
          <div class="text-warning text-bold">
            * Essa conta já foi finalizada. Por esse motivo não será possível editá-la!
          </div>
          <div class="columns">
            <div class="column col-4 col-sm-12 form-group">
              <label class="form-label">Paciente</label>
              <input type="text" class="form-input text-bold"
                     v-model="form.patient.name" readonly>
            </div>
            <div class="column col-4 col-sm-12 form-group">
              <label class="form-label">Convênio</label>
              <input type="text" class="form-input text-bold"
                     v-model="form.patient.insurance.name" readonly>
            </div>
            <div class="column col-4 col-sm-12 form-group">
              <label class="form-label">Data movimentação</label>
              <div class="input-group">
                <dx-input-date v-model="startDate"
                               class="text-center text-bold" readonly />
                <input type="text" class="form-input text-center text-bold"
                       v-model="startHour" v-mask="'00:00'" readonly>
              </div>
            </div>
          </div>
          <div class="divider" />
          <div class="columns">
            <div class="column col-4 form-group">
              <label class="form-label">Fatura</label>
              <input type="text" class="form-input text-bold"
                     v-model="form.invoice.code" readonly>
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">
                2 - Número da guia prestador
              </label>
              <input type="text" class="form-input" readonly
                     v-model="form.referral.provider">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">
                3 - Número da guia operadora
              </label>
              <input type="text" class="form-input" readonly
                     v-model="form.referral.insurance">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">Senha</label>
              <input type="text" class="form-input" readonly
                     v-model="form.authorization.password">
            </div>
          </div>
          <div class="columns">
            <div class="column col-4 form-group">
              <label class="form-label">4 - Número da carteirinha</label>
              <input type="text" class="form-input"
                     v-model="form.patient.insurance.insured.record"
                     v-mask-number readonly />
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">5 - Validade da carteira</label>
              <dx-input-date
                class="form-input" readonly
                v-model="form.patient.insurance.insured.validity"
              />
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">6 - Atendimento RN</label>
              <input type="text" class="form-input"
                     :value="form.patient.isNewborn ? 'Sim' : 'Não'"
                     readonly>
            </div>
            <div class="column col-6 form-group">
              <label class="form-label">7 - Nome do beneficiário</label>
              <input type="text" class="form-input" readonly
                     v-model="form.patient.insurance.insured.name">
            </div>
            <div class="column col-6 form-group">
              <label class="form-label">26 - Nome Social</label>
              <input type="text" class="form-input" readonly
                     v-model="form.patient.socialName">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">8 - Cartão Nacional de Saúde</label>
              <input type="text" class="form-input" readonly
                     v-model="form.patient.cns"
                     v-mask-number />
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">17 - Indicação de acidente</label>
              <input type="text" class="form-input" readonly
                     :value="accidentIndications[form.accidentIndication]">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">19 - Tipo da consulta</label>
              <input type="text" class="form-input" readonly
                     :value="consultationTypes[form.consultationType]">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">27 - Cobertura Especial</label>
              <input type="text" class="form-input" readonly
                     :value="specialCoverages[form.specialCoverage]">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">28 - Regime de atendimento</label>
              <input type="text" class="form-input" readonly
                     :value="serviceRegimes[form.serviceRegime]">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">29 - Saúde Ocupacional</label>
              <input type="text" class="form-input" readonly
                     :value="occupationalHealth[form.occupationalHealth]">
            </div>
            <div class="column col-12" v-if="info">
              <div class="has-error-message">
                <strong>
                  <fa-icon class="mr-1" :icon="['fal', 'clock']" />
                  <span>{{ info.message }}</span>
                </strong>
              </div>
            </div>
            <div class="column col-12 form-group">
              <label class="form-label">23 - Observação/Justificativa</label>
              <textarea class="form-input" rows="2"
                        v-model="form.justification" readonly />
            </div>
          </div>
          <div class="divider" />
          <div class="stenci-title">Procedimentos</div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>#</th>
              <th>Data</th>
              <th>Código</th>
              <th>Nome</th>
              <th>Profissional</th>
              <th class="text-center">Quantidade</th>
              <th class="text-right">Total</th>
              <th class="text-right"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(expense, i) in form.expenses" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ expense.startDate | date }}</td>
              <td>{{ expense.code | tuss }}</td>
              <td>{{ expense.name }}</td>
              <td>{{ expense.professional ? expense.professional.name : '-' }}</td>
              <td class="text-center">{{ expense.quantity }}</td>
              <td class="text-right">{{ expense.value.total | currency }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-primary tooltip tooltip-left"
                        data-tooltip="Procedimento já repassado ao profissional"
                        v-if="expense.financialBundleId"
                        @click="openTransaction(expense.financialBundleId)">
                  <fa-icon :icon="['fal', 'check-circle']" />
                </button>
                <button class="btn btn-sm btn-action btn-icon btn-neutral tooltip tooltip-left"
                        data-tooltip="Procedimento não repassado ao profissional" v-else>
                  <fa-icon :icon="['fal', 'times-circle']" />
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          <div class="columns">
            <div class="column col-5 col-sm-12 form-group">
              <label class="form-label">Paciente</label>
              <input type="text" class="form-input text-bold"
                     v-model="form.patient.name" readonly>
            </div>
            <div class="column col-4 col-sm-12 form-group">
              <label class="form-label">Convênio</label>
              <input type="text" class="form-input text-bold"
                     v-model="form.patient.insurance.name" readonly>
            </div>
            <div class="column col-3 col-sm-12 form-group">
              <label class="form-label">Data movimentação</label>
              <div class="input-group"
                   :class="{'has-error': $v.startDate.$error || $v.startHour.$error}">
                <dx-input-date v-model="startDate" class="text-center"
                               @blur="$v.startDate.$touch()"/>
                <input type="text" class="form-input text-center"
                       v-model="startHour" v-mask="'00:00'"
                       placeholder="00:00"
                       @blur="$v.startHour.$touch()">
              </div>
            </div>
          </div>
          <div class="divider" />
          <div class="columns">
            <div class="column col-3 form-group" v-if="invoiceCodes.length > 0">
              <label class="form-label">Fatura</label>
              <select id="invoice" v-if="hasInvoice"
                      class="form-select" v-model="form.invoiceId"
                      :disabled="source==='billing-invoices'">
                <option v-if="!isCurrentInvoice" value="">Fatura vigente</option>
                <option v-for="(item, i) in invoiceCodes"
                        :value="item.id" :key="i">
                  {{ item.code }} - {{ item.date | date }}
                  <span v-if="item.status === 'open'">(Vigente)</span>
                </option>
              </select>
              <input type="text" class="form-input"
                     v-model="form.invoice.code"
                     readonly v-else />
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.referral.provider.$error}">
              <label for="referral-provider-guide" class="form-label">
                2 - Nº guia prestador
              </label>
              <input type="text" id="referral-provider-guide" class="form-input" maxlength="20"
                     :disabled="disableReferralProvider()" autocomplete="nope"
                     v-model="form.referral.provider">
              <template v-if="$v.form.referral.provider.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.referral.provider.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.referral.insurance.$error}">
              <label for="referral-insurance" class="form-label">
                3 - Nº guia operadora
              </label>
              <input type="text" id="referral-insurance" class="form-input" maxlength="20"
                     v-model="form.referral.insurance" v-mask-number autocomplete="nope"
                     @change="fillProviderReferral">
              <template v-if="$v.form.referral.insurance.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.referral.insurance.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-3 form-group">
              <label for="authorization-password" class="form-label">Senha</label>
              <input type="text" id="authorization-password" class="form-input"
                     v-model="form.authorization.password">
            </div>
          </div>
          <div class="columns">
            <div class="column col-4 form-group"
                 :class="{'has-error': $v.form.patient.insurance.insured.record.$error}">
              <label for="referral-insured-record" class="form-label">
                4 - Número da carteirinha
              </label>
              <input type="text" id="referral-insured-record" class="form-input" autocomplete="nope"
                     @blur="$v.form.patient.insurance.insured.record.$touch()"
                     v-model="form.patient.insurance.insured.record" maxlength="20"
                     v-mask="'AAAAAAAAAAAAAAAAAAAA'" />
              <template v-if="$v.form.patient.insurance.insured.record.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.insurance.insured.record.required">
                  Campo obrigatório
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.patient.insurance.insured.record.minLength">
                  A carteira deve ter no mínimo {{planSettings.record.digits.min}} dígitos
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.patient.insurance.insured.record.maxLength">
                  A carteira deve ter no máximo {{planSettings.record.digits.max}} dígitos
                </div>
              </template>
            </div>
            <div class="column col-4 form-group"
                 :class="{'has-error': $v.form.patient.insurance.insured.validity.$error}">
              <label class="form-label">
                5 - Validade da carteira
              </label>
              <dx-input-date
                id="referral-insured-validity"
                autocomplete="nope"
                class="form-input" placeholder="00/00/0000"
                v-model="form.patient.insurance.insured.validity"
                @blur="$v.form.patient.insurance.insured.validity.$touch()"
              />
              <template v-if="$v.form.patient.insurance.insured.validity.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.insurance.insured.validity.required">
                  Campo obrigatório
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.patient.insurance.insured.validity.date">
                  Data inválida
                </div>
              </template>
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">6 - Atendimento RN</label>
              <select id="referral-patient-newborn" class="form-select"
                      v-model="form.patient.isNewborn">
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
            <div class="column col-6 form-group"
                 :class="{'has-error': $v.form.patient.insurance.insured.name.$error}">
              <label for="referral-insured-name" class="form-label">
                7 - Nome do beneficiário
              </label>
              <input type="text" id="referral-insured-name" class="form-input" autocomplete="nope"
                     @blur="$v.form.patient.insurance.insured.name.$touch()"
                     v-model="form.patient.insurance.insured.name">
              <template v-if="$v.form.patient.insurance.insured.name.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.insurance.insured.name.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-6 form-group">
              <label class="form-label">26 - Nome Social</label>
              <input type="text" class="form-input"
                     v-model="form.patient.socialName">
            </div>
            <div class="column col-4 form-group">
              <label for="referral-cns" class="form-label">
                8 - Cartão Nacional de Saúde
              </label>
              <input type="text" id="referral-cns" class="form-input"
                     autocomplete="nope" maxlength="20"
                     v-model="form.patient.cns"
                     placeholder="Cartão nacional de saúde"
                     v-mask-number />
            </div>
            <div class="column col-4 form-group"
                 :class="{'has-error': $v.form.accidentIndication.$error}">
              <label class="form-label">17 - Indicação de acidente</label>
              <select id="referral-accident-indication" @blur="$v.form.accidentIndication.$touch()"
                      class="form-select" v-model="form.accidentIndication">
                <option v-for="(text, value) in accidentIndications"
                        :value="value" :key="value">{{ text }}</option>
              </select>
              <template v-if="$v.form.accidentIndication.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.accidentIndication.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-4 form-group"
                 :class="{'has-error': $v.form.consultationType.$error}">
              <label class="form-label">19 - Tipo da consulta</label>
              <select id="referral-consultation-type" class="form-select"
                      @blur="$v.form.consultationType.$touch()"
                      v-model="form.consultationType">
                <option v-for="(text, value) in consultationTypes"
                        :value="value" :key="value">{{ text }}</option>
              </select>
              <template v-if="$v.form.consultationType.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.consultationType.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">27 - Cobertura Especial</label>
              <select id="referral-service-type" class="form-select"
                      v-model="form.specialCoverage">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in specialCoverages"
                        :value="value" :key="value">{{ text }}</option>
              </select>
            </div>
            <div class="column col-4 form-group"
                 :class="{'has-error': $v.form.serviceRegime.$error}">
              <label class="form-label">28 - Regime de atendimento</label>
              <select id="referral-service-type" class="form-select"
                      v-model="form.serviceRegime"
                      @blur="$v.form.serviceRegime.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in serviceRegimes"
                        :value="value" :key="value">{{ text }}</option>
              </select>
              <template v-if="$v.form.serviceRegime.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.serviceRegime.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">29 - Saúde Ocupacional</label>
              <select id="referral-service-type" class="form-select"
                      v-model="form.occupationalHealth">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in occupationalHealth"
                        :value="value" :key="value">{{ text }}</option>
              </select>
            </div>
            <div class="column col-12" v-if="info">
              <div class="has-error-message">
                <strong>
                  <fa-icon class="mr-1" :icon="['fal', 'clock']" />
                  <span>{{ info.message }}</span>
                </strong>
              </div>
            </div>
          </div>
          <div class="divider" />
          <expense-items
            :source="source"
            :expenses="form.expenses"
            :general-rules="planSettings.general"
            :insurance-plan-id="form.patient.insurance.plan.id"
          />
          <div class="column col-12 form-group">
            <label class="form-label">23 - Observação/Justificativa</label>
            <textarea id="notes" class="form-input" rows="2"
                      v-model="form.justification"/>
          </div>
        </template>
      </div>
      <template slot="footer">
        <button class="btn btn-primary mr-1" :class="{loading: saving}"
                v-if="!finished"
                @click="save" :disabled="saving || loading">
          Finalizar
        </button>
        <button class="btn"
                @click="close(false)">Sair</button>
      </template>
    </dx-modal>
    <master-password
      v-if="showMasterPassword"
      :show="showMasterPassword"
      @close="showMasterPassword = false"
      @result="setPermission"
    />
    <detail-modal
      id="modal-detail"
      v-if="modalDetail.show"
      :show="modalDetail.show"
      :transactionId="modalDetail.id"
      @close="closeModalDetail"
    />
  </div>
</template>

<script>
import formMixin from 'src/mixins/form';
import { mergeFrom } from '@/helpers/object';
import { mapActions, mapMutations, mapState } from 'vuex';
import { date, hour } from '@/data/validators';
import { required } from 'vuelidate/src/validators';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
import { consultationTypes } from '@/data/consultation-types';
import { accidentIndications } from '@/data/accident-indications';
import { occupationalHealth } from '@/data/occupational-health';
import { serviceRegimes } from '@/data/service-regimes';
import { specialCoverages } from '@/data/special-coverages';
import moment from 'moment';
import MasterPassword from '../../../../components/auth/MasterPassword.vue';
import ExpenseItems from '../../../billing/attendance/components/ExpenseItems.vue';
import DetailModal from '../../../billing/transaction/components/Detail.vue';

export default {
  components: {
    DetailModal,
    ExpenseItems,
    MasterPassword,
  },
  mixins: [formMixin],
  props: {
    source: {
      type: String,
    },
    show: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    data: {
      id: {
        type: String,
      },
      key: {
        type: String,
      },
      startDate: {
        type: String,
      },
      appointmentId: {
        type: String,
      },
      professionalId: {
        type: String,
      },
      invoiceId: {
        type: String,
      },
      referral: {
        type: {
          type: String,
          default: 'consultation',
        },
        serviceType: {
          type: String,
          default: 'consultation',
        },
        consultationType: {
          type: String,
          default: 'first',
        },
        provider: {
          type: String,
        },
        insurance: {
          type: String,
        },
        isNewborn: {
          type: Boolean,
          default: false,
        },
        accidentIndication: {
          type: String,
          default: 'not_accident',
        },
        notes: {
          type: String,
        },
      },
      authorization: {
        code: {
          type: String,
        },
        date: {
          type: Date,
        },
        password: {
          type: String,
        },
        validity: {
          type: String,
        },
        userId: {
          type: String,
        },
        authorizerId: {
          type: String,
        },
      },
      patient: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        birthDate: {
          type: Date,
        },
        gender: {
          type: Date,
        },
        insurance: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
          plan: {
            id: {
              type: String,
            },
            name: {
              type: String,
            },
          },
          insured: {
            record: {
              type: String,
            },
            name: {
              type: String,
            },
            validity: {
              type: Date,
            },
          },
        },
        cns: {
          type: String,
        },
      },
      expenses: {
        type: Array,
      },
    },
  },
  data() {
    return {
      path: '/billing-accounts',
      info: null,
      loading: false,
      saving: false,
      consultationTypes,
      accidentIndications,
      occupationalHealth,
      serviceRegimes,
      specialCoverages,
      isCurrentInvoice: false,
      allowed: false,
      professionals: [],
      invoiceCodes: [],
      startDate: '',
      startHour: '',
      planSettings: this.blankPlanSettingsForm(),
      showMasterPassword: false,
      form: this.blankForm(),
      modalDetail: {
        show: false,
        id: '',
      },
    };
  },
  validations() {
    const rules = {
      startDate: { required, date },
      startHour: { required, hour },
      form: {
        serviceType: { required },
        consultationType: { required },
        character: { required },
        accidentIndication: { required },
        patient: {
          insurance: {
            insured: {
              record: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(20),
              },
              name: { required },
              validity: { date },
            },
          },
        },
        referral: {
          type: { required },
          provider: {},
          insurance: {},
        },
        serviceRegime: { required },
      },
    };

    if (this.planSettings.record.required) {
      rules.form.patient.insurance.insured.record = {
        required,
        minLength: minLength(1),
        maxLength: maxLength(20),
      };
      rules.form.patient.insurance.insured.name = {
        required,
      };
    }
    if (this.planSettings.record.requiredValidity) {
      rules.form.patient.insurance.insured.validity = {
        required, date,
      };
    }
    if (this.planSettings.record.digits.enabled) {
      rules.form.patient.insurance.insured.record = {
        required,
        minLength: minLength(this.planSettings.record.digits.min),
        maxLength: maxLength(this.planSettings.record.digits.max),
      };
    }

    if (this.planSettings.consultation.requiredInsuranceReferral
      && this.form.consultationType !== 'return') {
      rules.form.referral.insurance = { required };
    }

    if (this.planSettings.providerReferral.mode === 'insurance_referral') {
      rules.form.referral.insurance = {
        required,
      };
    } else {
      rules.form.referral.provider = {
        required,
      };
    }

    return rules;
  },
  created() {
    this.form = mergeFrom(this.blankForm(), this.data);
    this.startDate = moment(this.form.startDate).format('YYYY-MM-DD');
    this.startHour = moment(this.form.startDate).format('HH-mm');

    if (this.source === 'appointments' && !this.form.id) {
      const [expense] = this.form.expenses;
      this.form.character = 'elective';
      this.form.serviceType = 'consultation';
      this.form.consultationType = expense.return ? 'return' : 'first';
    }
  },
  async mounted() {
    this.loading = true;
    await this.loadProfessionals();
    await this.loadInvoiceCodes();
    await this.loadPlanSettings();
    this.loading = false;
    this.$v.form.$touch();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      appointment: state => state.appointment.form,
    }),
    finished() {
      return this.form.status === 'finished';
    },
    hasInvoice() {
      return this.invoiceCodes.some(({ id }) => id === this.form.invoiceId);
    },
  },
  methods: {
    ...mapMutations({
      setAccount: 'Appointment.SET_ACCOUNT',
    }),
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    close(save = false) {
      this.$emit('close', save ? this.form : null);
    },
    validateItems() {
      return this.$refs.expenseItems.every(item => item.validate());
    },
    openTransaction(id) {
      this.modalDetail.id = id;
      this.modalDetail.show = true;
    },
    save() {
      this.$v.startDate.$touch();
      this.$v.startHour.$touch();
      this.$v.form.$touch();
      if (this.$v.startDate.$error
        || this.$v.startHour.$error
        || this.$v.form.$error
        || this.saving) {
        return null;
      }

      if (this.form.expenses.length === 0) {
        this.$toast.error('Nenhum procedimento adicionado!');
        return null;
      }

      if (!this.allowed && this.info && this.info.requiredAuthorization) {
        this.showMasterPassword = true;
        return null;
      }

      this.saving = true;
      this.form.startDate = `${this.startDate} ${this.startHour}`;
      this.form.endDate = `${this.startDate} ${this.startHour}`;

      const branch = this.user.branch
        && this.user.branch.id
        ? { id: this.user.branch.id, name: this.user.branch.name }
        : null;

      const data = {
        id: this.form.id,
        appointmentId: this.form.appointmentId,
        branch,
        status: 'open',
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        invoiceId: this.form.invoiceId,
        referral: {
          type: this.form.referral.type,
          insurance: this.form.referral.insurance,
          provider: this.form.referral.provider,
        },
        authorization: {
          password: this.form.authorization.password,
        },
        insurance: {
          id: this.form.patient.insurance.id,
          planId: this.form.patient.insurance.plan.id,
          insured: {
            record: this.form.patient.insurance.insured.record,
            name: this.form.patient.insurance.insured.name,
            validity: this.form.patient.insurance.insured.validity,
          },
        },
        patient: {
          id: this.form.patient.id,
          name: this.form.patient.name,
          cns: this.form.patient.cns,
          isNewborn: this.form.patient.isNewborn,
          socialName: this.form.patient.socialName,
        },
        serviceType: 'consultation',
        consultationType: this.form.consultationType,
        character: 'elective',
        accidentIndication: this.form.accidentIndication,
        justification: this.form.justification,
        occupationalHealth: this.form.occupationalHealth,
        serviceRegime: this.form.serviceRegime,
        specialCoverage: this.form.specialCoverage,
        expenses: this.form.expenses.map(expense => ({
          id: expense.id,
          financialBundleId: expense.financialBundleId,
          startDate: expense.startDate,
          endDate: expense.endDate,
          code: expense.code,
          name: expense.name,
          accountId: expense.accountId,
          quantity: expense.quantity,
          professionalId: expense.professional.id,
          specialtyCode: expense.professional.specialty.code,
          accreditedProfessionalId: expense.professional.accredited
            ? expense.professional.accredited.id : null,
          accreditedSpecialtyCode: expense.professional.accredited
            && expense.professional.accredited.specialty
            ? expense.professional.accredited.specialty.code : null,
          degreeParticipationCode: expense.professional.degreeParticipation,
          way: expense.way ? expense.way : null,
          technique: expense.technique ? expense.technique : null,
          factor: expense.factor,
          value: {
            discount: expense.value.discount || 0,
            interest: expense.value.interest || 0,
            rate: expense.value.rate || 0,
            insurance: expense.value.insurance || 0,
            particular: expense.value.particular || 0,
          },
        })),
      };

      const isNew = !data.id;

      const request = isNew
        ? this.$httpX.post(`${this.path}`, data)
        : this.$httpX.put(`${this.path}/${data.id}`, data);

      return request
        .then(({ data: result }) => {
          this.form.id = result.id;

          result.expenses.forEach((item) => {
            const found = this.$store.state.appointment.form.expenses
              .find(({ id }) => id === item.expense.id);
            if (found) {
              found.return = item.return || false;
              found.value.discount = item.value.discount;
              found.value.feeValue = item.value.fee;
              found.value.filmValue = item.value.film;
              found.value.insurance = item.value.insurance;
              found.value.interest = item.value.interest;
              found.value.operationalCostValue = item.value.operationalCost;
              found.value.particular = item.value.particular;
              found.value.rate = item.value.rate;
              found.value.tax = item.value.tax;
              if (!found.accountId) {
                found.accountId = result.id;
                found.selected = false;
              }
            }
          });

          this.setAccount({
            key: this.data.key,
            expenses: this.$store.state.appointment.form.expenses,
          });

          this.$toast.show('Salvo com sucesso');
          this.close(true);
        })
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.show(e.response.data.message, { type: 'error', timeout: 5000 });
          }
        })
        .then(() => {
          this.saving = false;
        });
    },
    async closeModalDetail(save) {
      if (save) {
        this.$http.get(`/financial-transactions/${this.modalDetail.id}`)
          .catch((e) => {
            if (e.response && e.response.status === 404) {
              const items = this.form.expenses
                .filter(({ financialBundleId }) => financialBundleId === this.modalDetail.id);
              items.forEach((item) => {
                item.financialBundleId = null;
                return item;
              });
            }
          });
      }
      this.modalDetail.show = false;
    },
    setPermission(allow) {
      this.showMasterPassword = false;
      this.allowed = allow;
    },
    async loadPlanSettings() {
      const insuranceId = this.form.patient.insurance.id;
      const planId = this.form.patient.insurance.plan.id;
      const params = `/insurances/${insuranceId}/plans/${planId}`;
      if (this.form.patient.insurance.plan.id) {
        await this.$http.get(params)
          .then(({ data }) => {
            if (data.settings.general.record) {
              this.planSettings.record = data.settings.general.record;
            }
            if (data.settings.general.providerReferral) {
              const provider = data.settings.general.providerReferral.mode;
              this.planSettings.providerReferral.mode = provider;
            }
            if (data.settings.tiss.consultation) {
              const consultation = data.settings.tiss.consultation.requiredInsuranceReferral;
              this.planSettings.consultation.requiredInsuranceReferral = consultation;
            }
            if (data.settings.general.billing) {
              if (data.settings.general.billing.blockValues) {
                this.planSettings.general.billing.blockValues = data.settings
                  .general.billing.blockValues;
              }
              if (data.settings.general.billing.hideInsuranceValue) {
                this.planSettings.general.billing.hideInsuranceValue = data.settings
                  .general.billing.hideInsuranceValue;
              }
            }
          })
          .catch(() => {
          });
      }

      if (this.form.patient.insurance.insured.name === '') {
        if (this.source === 'appointments') {
          this.form.patient.insurance.insured.name = this.appointment.patient.name;
        }
      }
      if (this.planSettings.providerReferral.mode === 'automatic') {
        if (!this.form.referral.provider) {
          this.form.referral.provider = 'GUIA_AUTOMATICA';
        }
      }
    },
    async loadInvoiceCodes() {
      const params = {
        insurancePlanId: this.data.patient.insurance.plan.id,
      };

      return this.$http.get('/billing-invoice-codes', { params })
        .then(({ data }) => {
          this.invoiceCodes = data.items;
          const found = this.invoiceCodes.find(({ status }) => status === 'open');
          this.isCurrentInvoice = !!found;

          if (!this.form.invoiceId && this.isCurrentInvoice) {
            if (found) {
              this.form.invoiceId = found.id;
            } else {
              this.form.invoiceId = this.invoiceCodes[0].id;
            }
          }
        })
        .catch(() => {});
    },
    async loadProfessionals() {
      const councilsToFilter = [
        'COREN',
        'CREF',
        'CRFA',
        'CRM',
        'CRO',
        'CREFITO',
        'CRN',
        'CRP',
        'CREFONO',
      ];

      return this.apiFetchProfessionals()
        .then((items) => {
          items.forEach((item) => {
            const councils = item.professional.councils.map(councilItem => councilItem.name);
            if (councils && councils.length > 0) {
              const filtered = councils.filter(el => councilsToFilter.includes(el));
              if (filtered.length > 0) {
                item.professional.specialties.forEach((specialty) => {
                  this.professionals.push({
                    id: item.id,
                    name: item.name,
                    specialty,
                  });
                });
              }
            }
          });
          let filtered = this.professionals.filter(item => item.id === this.form.professionalId);
          if (filtered.length === 0) {
            this.form.professionalId = '';
          }
          this.form.expenses.forEach((expense) => {
            filtered = this.professionals.filter(item => item.id === expense.professional.id);
            if (filtered.length === 0) {
              expense.professional.id = '';
            }
          });
        })
        .catch(() => {});
    },
    fillProviderReferral() {
      if (this.planSettings.providerReferral.mode === 'insurance_referral') {
        this.form.referral.provider = this.form.referral.insurance;
      }
    },
    disableReferralProvider() {
      const referralType = this.planSettings.providerReferral.mode;
      return referralType === 'insurance_referral' || referralType === 'automatic';
    },
    blankPlanSettingsForm() {
      return {
        providerReferral: {
          mode: 'manual',
        },
        consultation: {
          requiredInsuranceReferral: false,
        },
        record: {
          required: true,
          requiredValidity: false,
          fillValidityWithServiceDate: false,
          digits: {
            enabled: false,
            min: 0,
            max: 0,
          },
        },
        general: {
          billing: {
            blockValues: false,
            hideInsuranceValue: false,
          },
        },
      };
    },
    blankForm() {
      return {
        id: '',
        key: '',
        status: '',
        startDate: '',
        endDate: '',
        appointmentId: '',
        professionalId: '',
        specialtyCode: '',
        invoiceId: '',
        invoice: {
          id: '',
          code: '',
        },
        referral: {
          type: 'consultation',
          provider: '',
          insurance: '',
          main: '',
        },
        patient: {
          id: '',
          name: '',
          socialName: '',
          birthDate: '',
          gender: '',
          isNewborn: false,
          insurance: {
            id: '',
            name: '',
            type: '',
            plan: {
              id: '',
              name: '',
              settings: {
                tiss: {
                  enabled: false,
                  consultationInSadt: false,
                },
                record: {
                  required: false,
                  requiredValidity: false,
                  digits: {
                    enabled: false,
                    min: 0,
                    max: 0,
                  },
                  fillValidityWithServiceDate: false,
                },
              },
            },
            insured: {
              record: '',
              name: '',
              validity: '',
            },
          },
          cns: '',
        },
        authorization: {
          date: null,
          password: '',
          validity: null,
        },
        request: {
          date: '',
          professional: {
            id: '',
            name: '',
            council: {
              name: '',
              state: '',
              record: '',
            },
            specialty: {
              code: '',
              name: '',
            },
          },
        },
        serviceType: 'consultation',
        consultationType: '',
        character: 'elective',
        accidentIndication: 'not_accident',
        justification: '',
        clinicalIndication: '',
        closureReason: '',
        occupationalHealth: '',
        serviceRegime: 'outpatient',
        specialCoverage: '',
        installments: [],
        expenses: [],
        total: 0,
        courtesy: false,
        paymentDate: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
.insurance-account {
}
</style>
