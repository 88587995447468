<template>
  <div>
    <dx-modal title="Guia de SP/SADT"
              id="modal-sadt-account" size="xl"
              :value="show" @input="close(false)">
      <div class="loading loading-lg" v-if="loading" />
      <div class="insurance-account" v-else>
        <template v-if="finished">
          <div class="text-warning text-bold">
            * Essa conta já foi fechada ou finalizada. Por esse motivo não será possível editá-la!
          </div>
          <div class="columns">
            <div class="column col-5 col-sm-12 form-group">
              <label class="form-label">Paciente</label>
              <input type="text" class="form-input text-bold"
                     v-model="form.patient.name" readonly>
            </div>
            <div class="column col-4 col-sm-12 form-group">
              <label class="form-label">Convênio</label>
              <input type="text" class="form-input text-bold"
                     v-model="form.patient.insurance.name" readonly>
            </div>
            <div class="column col-3 col-sm-12 form-group">
              <label class="form-label">Data movimentação</label>
              <div class="input-group">
                <dx-input-date v-model="startDate"
                               class="text-center text-bold" readonly />
                <input type="text" class="form-input text-center text-bold"
                       v-model="startHour" v-mask="'00:00'" readonly>
              </div>
            </div>
          </div>
          <div class="divider" />
          <div class="columns">
            <div class="column col-3 form-group">
              <label for="referral-provider" class="form-label">1 - Registro ANS</label>
              <input type="text" class="form-input" readonly />
            </div>
            <div class="column col-3 form-group">
              <label for="referral-provider" class="form-label">
                2 - Número da guia prestador
              </label>
              <input type="text" class="form-input" readonly
                     v-model="form.referral.provider" />
            </div>
            <div class="column col-3 form-group">
              <label for="referral-main" class="form-label">
                3 - Número da guia principal
              </label>
              <input type="text" class="form-input"
                     v-model="form.referral.main"
                     v-mask-number readonly />
            </div>
            <div class="column col-3 form-group">
              <label class="form-label">Fatura</label>
              <input type="text" class="form-input"
                     v-model="form.invoice.code"
                     readonly />
            </div>
            <div class="column col-3 form-group">
              <label class="form-label">4 - Data da autorização</label>
              <dx-input-date class="form-input" readonly
                             v-model="form.authorization.date" />
            </div>
            <div class="column col-3 form-group">
              <label for="authorization-password" class="form-label">5 - Senha</label>
              <input type="text" class="form-input" readonly
                     v-model="form.authorization.password">
            </div>
            <div class="column col-3 form-group">
              <label class="form-label">6 - Data de validade</label>
              <dx-input-date
                class="form-input" readonly
                v-model="form.authorization.validity"
              />
            </div>
            <div class="column col-3 form-group">
              <label for="referral-insurance" class="form-label">
                7 - Número da guia operadora
              </label>
              <input type="text" class="form-input" readonly
                     v-model="form.referral.insurance">
            </div>
          </div>
          <h6 class="h6">Dados do Beneficiário</h6>
          <div class="columns">
            <div class="column col-2 form-group">
              <label for="referral-insured-record" class="form-label">
                8 - Carteirinha
              </label>
              <input type="text" class="form-input" readonly v-mask-number
                     v-model="form.patient.insurance.insured.record"
              />
            </div>
            <div class="column col-2 form-group">
              <label class="form-label">9 - Validade</label>
              <dx-input-date
                class="form-input" readonly
                v-model="form.patient.insurance.insured.validity"
              />
            </div>
            <div class="column col-4 form-group">
              <label for="patient-insurance-insured" class="form-label">
                10 - Nome do beneficiário
              </label>
              <input type="text" class="form-input" readonly
                     v-model="form.patient.insurance.insured.name">
            </div>
            <div class="column col-2 form-group">
              <label for="patient-cns" class="form-label">11 - CNS</label>
              <input type="text" class="form-input" readonly
                     v-model="form.patient.cns">
            </div>
            <div class="column col-2 form-group">
              <label class="form-label">12 - Atendimento RN</label>
              <input type="text" class="form-input" readonly
                     :value="form.patient.isNewborn ? 'Sim' : 'Não'">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">89 - Nome Social</label>
              <input type="text" class="form-input" readonly
                     v-model="form.patient.socialName">
            </div>
          </div>
          <h6 class="h6 mt-2">Dados do Solicitante</h6>
          <div class="columns">
            <div class="column col-2 form-group">
              <label class="form-label">16 - Conselho</label>
              <input type="text" class="form-input" readonly
                     v-model="form.request.professional.council.name">
            </div>
            <div class="column col-2 form-group">
              <label for="request-professional-council-record"
                     class="form-label">17 - Registro</label>
              <input type="text" class="form-input" readonly
                     v-model="form.request.professional.council.record">
            </div>
            <div class="column col-1 form-group">
              <label class="form-label">18 - UF</label>
              <input type="text" class="form-input" readonly
                     v-model="form.request.professional.council.state">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">15 - Nome do profissional</label>
              <input type="text" class="form-input" readonly
                     v-model="form.request.professional.name" />
            </div>
            <div class="column col-3 form-group">
              <label class="form-label">19 - Especialidade</label>
              <input type="text" class="form-input" readonly
                     v-model="form.request.professional.specialty.name">
            </div>
          </div>
          <h6 class="h6">Dados da Solicitação</h6>
          <div class="columns">
            <div class="column col-3 form-group">
              <label class="form-label">21 - Caráter do atendimento</label>
              <input type="text" class="form-input" readonly
                     :value="form.character === 'elective' ? 'Eletivo' : 'Urgência/Emergência'">
            </div>
            <div class="column col-3 form-group">
              <label class="form-label">22 - Data da solicitação</label>
              <dx-input-date
                class="form-input" readonly
                v-model="form.request.date"
              />
            </div>
            <div class="column col-3 form-group">
              <label for="clinical-indication"
                     class="form-label">23 - Indicação clínica</label>
              <input type="text" class="form-input" readonly
                     v-model="form.clinicalIndication">
            </div>
            <div class="column col-3 form-group">
              <label class="form-label">90 - Cobertura Especial</label>
              <input type="text" class="form-input" readonly
                     :value="specialCoverages[form.specialCoverage]">
            </div>
          </div>
          <h6 class="h6">Dados do Atendimento</h6>
          <div class="columns">
            <div class="column col-4 form-group">
              <label class="form-label">32 - Tipo do atendimento</label>
              <input type="text" class="form-input" readonly
                     :value="serviceTypes[form.serviceType]">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">33 - Indicação de acidente</label>
              <input type="text" class="form-input" readonly
                     :value="accidentIndications[form.accidentIndication]">
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">34 - Tipo da consulta</label>
              <input type="text" class="form-input" readonly
                     :value="consultationTypes[form.consultationType]">
            </div>
            <div class="column col-6 form-group">
              <label class="form-label">35 - Motivo do encerramento</label>
              <input type="text" class="form-input" readonly
                     :value="closureReasons[form.closureReason]">
            </div>
            <div class="column col-3 form-group">
              <label class="form-label">91 - Regime de atendimento</label>
              <input type="text" class="form-input" readonly
                     :value="serviceRegimes[form.serviceRegime]">
            </div>
            <div class="column col-3 form-group">
              <label class="form-label">92 - Saúde Ocupacional</label>
              <input type="text" class="form-input" readonly
                     :value="occupationalHealth[form.occupationalHealth]">
            </div>
            <div class="column col-12 form-group">
              <label class="form-label">58 - Observação/Justificativa</label>
              <textarea class="form-input" rows="2"
                        v-model="form.justification" readonly />
            </div>
          </div>
          <div class="h6 mt-2">Procedimentos</div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>#</th>
              <th>Data</th>
              <th>Código</th>
              <th>Nome</th>
              <th>Profissional</th>
              <th class="text-center">Quantidade</th>
              <th class="text-right">Total</th>
              <th class="text-right"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(expense, i) in form.expenses" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ expense.startDate | date }}</td>
              <td>{{ expense.code | tuss }}</td>
              <td>{{ expense.name }}</td>
              <td>{{ expense.professional ? expense.professional.name : '-' }}</td>
              <td class="text-center">{{ expense.quantity }}</td>
              <td class="text-right">{{ expense.value.total | currency }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-primary tooltip tooltip-left"
                        data-tooltip="Procedimento já repassado ao profissional"
                        v-if="expense.financialBundleId"
                        @click="openTransaction(expense.financialBundleId)">
                  <fa-icon :icon="['fal', 'check-circle']" />
                </button>
                <button class="btn btn-sm btn-action btn-icon btn-neutral tooltip tooltip-left"
                        data-tooltip="Procedimento não repassado ao profissional" v-else>
                  <fa-icon :icon="['fal', 'times-circle']" />
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          <div class="columns">
            <div class="column col-5 col-sm-12 form-group">
              <label class="form-label">Paciente</label>
              <input type="text" class="form-input text-bold"
                     v-model="form.patient.name" readonly>
            </div>
            <div class="column col-4 col-sm-12 form-group">
              <label class="form-label">Convênio</label>
              <input type="text" class="form-input text-bold"
                     v-model="form.patient.insurance.name" readonly>
            </div>
            <div class="column col-3 col-sm-12 form-group">
              <label class="form-label">Data movimentação</label>
              <div class="input-group"
                   :class="{'has-error': $v.startDate.$error || $v.startHour.$error}">
                <dx-input-date v-model="startDate" class="text-center"
                               @blur="$v.startDate.$touch()"/>
                <input type="text" class="form-input text-center"
                       v-model="startHour" v-mask="'00:00'"
                       placeholder="00:00"
                       @blur="$v.startHour.$touch()">
              </div>
            </div>
          </div>
          <div class="divider" />
          <div class="columns">
            <div class="column col-3 form-group">
              <label for="referral-provider" class="form-label">1 - Registro ANS</label>
              <input type="text" class="form-input" readonly />
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.referral.provider.$error}">
              <label for="referral-provider" class="form-label">
                2 - Número da guia prestador
              </label>
              <input type="text" id="referral-provider" class="form-input"
                     autocomplete="nope" maxlength="20"
                     :disabled="planSettings.providerReferral.mode!=='manual'"
                     v-model="form.referral.provider"
                     @blur="$v.form.referral.provider.$touch()" />
              <template v-if="$v.form.referral.provider.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.referral.provider.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.referral.main.$error}">
              <label for="referral-main" class="form-label">
                3 - Número da guia principal
              </label>
              <input type="text" id="referral-main" class="form-input" maxlength="20"
                     v-model="form.referral.main"
                     autocomplete="nope" @blur="$v.form.referral.main.$touch()"
                     v-mask-number />
              <template v-if="$v.form.referral.main.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.referral.main.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-3 form-group" v-if="invoiceCodes.length > 0">
              <label class="form-label">Fatura</label>
              <select id="invoice" v-if="hasInvoice"
                      class="form-select" v-model="form.invoiceId"
                      :disabled="source==='billing-invoices'">
                <option v-if="!isCurrentInvoice" value="">Fatura vigente</option>
                <option v-for="(item, i) in invoiceCodes"
                        :value="item.id" :key="i">
                  {{ item.code }} - {{ item.date | date }}
                  <span v-if="item.status === 'open'">(Vigente)</span>
                </option>
              </select>
              <input type="text" class="form-input"
                     v-model="form.invoice.code"
                     readonly v-else />
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.authorization.date.$error}">
              <label class="form-label">4 - Data da autorização</label>
              <dx-input-date
                id="authorization-date"
                autocomplete="nope"
                class="form-input" placeholder="00/00/0000"
                v-model="form.authorization.date"
                @change="focusAuthorizationValidity"
                @focus="focusAuthorizationDate"
                @blur="$v.form.authorization.date.$touch()"
              />
              <template v-if="$v.form.authorization.date.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.authorization.date.required">
                  Campo obrigatório
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.authorization.date.date">Data inválida
                </div>
              </template>
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.authorization.password.$error}">
              <label for="authorization-password" class="form-label">5 - Senha</label>
              <input type="text" id="authorization-password" class="form-input"
                     @change="changePassword"
                     v-model="form.authorization.password" autocomplete="nope"
                     @blur="$v.form.authorization.password.$touch()">
              <template v-if="$v.form.authorization.password.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.authorization.password.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.authorization.validity.$error}">
              <label class="form-label">6 - Data de validade</label>
              <dx-input-date
                id="authorization-validity"
                autocomplete="nope"
                class="form-input" placeholder="00/00/0000"
                v-model="form.authorization.validity"
                @focus="focusAuthorizationValidity"
                @blur="$v.form.authorization.validity.$touch()"
              />
              <template v-if="$v.form.authorization.validity.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.authorization.validity.required">
                  Campo obrigatório
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.authorization.validity.date">Data inválida
                </div>
              </template>
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.referral.insurance.$error}">
              <label for="referral-insurance" class="form-label">
                7 - Número da guia operadora
              </label>
              <input type="text" id="referral-insurance" class="form-input" autocomplete="nope"
                     @change="changeInsuranceReferral" v-model="form.referral.insurance"
                     @blur="$v.form.referral.insurance.$touch()">
              <template v-if="$v.form.referral.insurance.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.referral.insurance.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
          </div>
          <h6 class="h6">Dados do Beneficiário</h6>
          <div class="columns">
            <div class="column col-2 form-group"
                 :class="{'has-error': $v.form.patient.insurance.insured.record.$error}">
              <label for="referral-insured-record" class="form-label">8 - Carteirinha</label>
              <input type="text" id="referral-insured-record" class="form-input"
                     autocomplete="nope"
                     v-model="form.patient.insurance.insured.record" maxlength="20"
                     v-mask="'AAAAAAAAAAAAAAAAAAAA'"
                     @blur="$v.form.patient.insurance.insured.record.$touch()"/>
              <template v-if="$v.form.patient.insurance.insured.record.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.insurance.insured.record.required">
                  Campo obrigatório
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.patient.insurance.insured.record.minLength">
                  A carteira deve ter no mínimo {{planSettings.record.digits.min}} dígitos
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.patient.insurance.insured.record.maxLength">
                  A carteira deve ter no máximo {{planSettings.record.digits.max}} dígitos
                </div>
              </template>
            </div>
            <div class="column col-2 form-group"
                 :class="{'has-error': $v.form.patient.insurance.insured.validity.$error}">
              <label class="form-label">
                9 - Validade
              </label>
              <dx-input-date
                id="referral-insured-validity"
                autocomplete="nope"
                class="form-input" placeholder="00/00/0000"
                v-model="form.patient.insurance.insured.validity"
                @blur="$v.form.patient.insurance.insured.validity.$touch()"
              />
              <template v-if="$v.form.patient.insurance.insured.validity.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.insurance.insured.validity.required">
                  Campo obrigatório
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.patient.insurance.insured.validity.date">
                  Data inválida
                </div>
              </template>
            </div>
            <div class="column col-4 form-group"
                 :class="{'has-error': $v.form.patient.insurance.insured.name.$error}">
              <label for="patient-insurance-insured" class="form-label">
                10 - Nome do beneficiário
              </label>
              <input type="text" id="patient-insurance-insured" class="form-input"
                     autocomplete="nope" v-model="form.patient.insurance.insured.name"
                     @blur="$v.form.patient.insurance.insured.name.$touch()">
              <template v-if="$v.form.patient.insurance.insured.name.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.insurance.insured.name.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-2 form-group">
              <label for="patient-cns" class="form-label">11 - CNS</label>
              <input type="text" id="patient-cns" class="form-input" autocomplete="nope"
                     v-model="form.patient.cns" placeholder="Cartão nacional de saúde">
            </div>
            <div class="column col-2 form-group">
              <label class="form-label">12 - Atendimento RN</label>
              <select id="patient-newborn" class="form-select" v-model="form.patient.isNewborn">
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
            <div class="column col-4 form-group">
              <label class="form-label">89 - Nome Social</label>
              <input type="text" class="form-input" v-model="form.patient.socialName"
                     placeholder="Nome social (opcional)">
            </div>
          </div>
          <h6 class="h6 mt-2">Dados do Solicitante</h6>
          <div class="columns">
            <div class="column col-2 form-group"
                 :class="{'has-error': $v.form.request.professional.council.name.$error}">
              <label class="form-label">16 - Conselho</label>
              <select id="request-professional-council-name"
                      class="form-select" v-model="form.request.professional.council.name"
                      @blur="$v.form.request.professional.council.name.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in council.councils"
                        :value="value" :key="value">{{ value }}</option>
              </select>
              <template v-if="$v.form.request.professional.council.name.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.request.professional.council.name.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-1 form-group"
                 :class="{'has-error': $v.form.request.professional.council.state.$error}">
              <label class="form-label">18 - UF</label>
              <select id="request-professional-council-state"
                      class="form-select" v-model="form.request.professional.council.state"
                      @blur="$v.form.request.professional.council.state.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in states"
                        :value="value" :key="value">{{ value }}</option>
              </select>
              <template v-if="$v.form.request.professional.council.state.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.request.professional.council.state.required">
                  Obrigatório
                </div>
              </template>
            </div>
            <div class="column col-2 form-group"
                 :class="{'has-error': $v.form.request.professional.council.record.$error}">
              <label for="request-professional-council-record"
                     class="form-label">17 - Registro</label>
              <div class="input-group">
                <input type="text" id="request-professional-council-record" class="form-input"
                       v-model="form.request.professional.council.record" autocomplete="nope"
                       :disabled="hasRequesterCouncil"
                       v-mask-number @change="findProfessionalRequesterByCouncil"
                       @blur="$v.form.request.professional.council.record.$touch()">
                <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                        :class="{loading: loadingRequester}"
                        :disabled="loadingRequester || hasRequesterCouncil"
                        tabindex="-1" @click="findProfessionalRequesterByCouncil">
                  <fa-icon :icon="['fal', 'search']"/>
                </button>
              </div>
              <template v-if="$v.form.request.professional.council.record.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.request.professional.council.record.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-4 form-group"
                 :class="{'has-error': $v.form.request.professional.name.$error}">
              <label class="form-label">15 - Nome do profissional</label>
              <input type="text" id="request-professional" class="form-input"
                     v-model="form.request.professional.name" autocomplete="nope"
                     @blur="$v.form.request.professional.name.$touch()" />
              <template v-if="$v.form.request.professional.name.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.request.professional.name.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.requestSpecialty.$error}">
              <label class="form-label">19 - Especialidade</label>
              <dx-autocomplete
                v-model="requestSpecialty"
                :source="findCbo"
                label="name"
                track-by="id"
                :readonly="!!form.request.professional.specialty.code"
                :debounce="800"
                @select="addCbo"
                @blur="$v.requestSpecialty.$touch()"
                placeholder="Informe o código ou nome..."
                input-id="expense-name">
                <button slot="action" @click="unsetCbo"
                        class="btn btn-action input-group-btn btn-icon btn-gray"
                        tabindex="-1">
                  <fa-icon :icon="['fal', 'times']">
                  </fa-icon>
                </button>
                <template v-slot="{ item }">
                  <a>
                    <fa-icon :icon="['fal', 'file-medical-alt']" class="text-primary"/>
                    <span class="ml-1">
                  {{ item.code }} - {{ item.name }}
                </span>
                  </a>
                </template>
              </dx-autocomplete>
              <template v-if="$v.requestSpecialty.$error">
                <div class="form-input-hint"
                     v-if="!$v.requestSpecialty.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-12 form-group">
              <button class="btn btn-gray btn-sm form-inline" @click="getProfessionalExecutantData">
                clique aqui para preencher os campos acima com os dados do profissional executante
              </button>
            </div>
          </div>
          <h6 class="h6 mt-2">Dados da Solicitação</h6>
          <div class="columns">
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.character.$error}">
              <label class="form-label">21 - Caráter do atendimento</label>
              <select id="service-character" class="form-select" v-model="form.character"
                      @blur="$v.form.character.$touch()">
                <option v-for="(text, value) in characterServices"
                        :value="value" :key="value">{{ text }}</option>
              </select>
              <template v-if="$v.form.character.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.character.required">Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.request.date.$error}">
              <label class="form-label">22 - Data da solicitação</label>
              <dx-input-date
                id="request-date"
                class="form-input" placeholder="00/00/0000"
                autocomplete="nope"
                v-model="form.request.date"
                @focus="focusRequestDate"
                @blur="$v.form.request.date.$touch()"
              />
              <template v-if="$v.form.request.date.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.request.date.required">Campo obrigatório
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.request.date.date">Data inválida
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.request.date.minDate">
                  A data da solicitação não pode ser maior que a data do atendimento
                </div>
              </template>
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.clinicalIndication.$error}">
              <label for="clinical-indication"
                     class="form-label">23 - Indicação clínica</label>
              <input type="text" id="clinical-indication" class="form-input" autocomplete="nope"
                     v-model="form.clinicalIndication">
              <template v-if="$v.form.clinicalIndication.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.clinicalIndication.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-3 form-group">
              <label class="form-label">90 - Cobertura Especial</label>
              <select id="referral-service-type" class="form-select"
                      v-model="form.specialCoverage">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in specialCoverages"
                        :value="value" :key="value">{{ text }}</option>
              </select>
            </div>
          </div>
          <h6 class="h6">Dados do Atendimento</h6>
          <div class="columns">
            <div class="column col-4 form-group"
                 :class="{'has-error': $v.form.serviceType.$error}">
              <label class="form-label">32 - Tipo do atendimento</label>
              <select id="referral-service-type" class="form-select"
                      v-model="form.serviceType" @blur="$v.form.serviceType.$touch()"
                      @change="clearConsultation">
                <option v-for="(text, value) in serviceTypes"
                        :value="value" :key="value">{{ text }}</option>
              </select>
              <template v-if="$v.form.serviceType.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.serviceType.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-4 form-group"
                 :class="{'has-error': $v.form.accidentIndication.$error}">
              <label class="form-label">33 - Indicação de acidente</label>
              <select id="accident-indication" class="form-select" v-model="form.accidentIndication"
                      @blur="$v.form.accidentIndication.$touch()">
                <option v-for="(text, value) in accidentIndications"
                        :value="value" :key="value">{{ text }}</option>
              </select>
              <template v-if="$v.form.accidentIndication.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.accidentIndication.required">Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-4 form-group"
                 :class="{'has-error': $v.form.consultationType.$error}">
              <label class="form-label">34 - Tipo da consulta</label>
              <select id="consultation-type" class="form-select"
                      v-model="form.consultationType"
                      @blur="$v.form.consultationType.$touch()"
                      :disabled="form.serviceType !== 'consultation'">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in consultationTypes"
                        :value="value" :key="value">{{ text }}</option>
              </select>
              <template v-if="$v.form.consultationType.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.consultationType.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-6 form-group">
              <label class="form-label">35 - Motivo do encerramento</label>
              <select id="closure-reason" class="form-select" v-model="form.closureReason">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in closureReasons"
                        :value="value" :key="value">{{ text }}</option>
              </select>
            </div>
            <div class="column col-3 form-group"
                 :class="{'has-error': $v.form.serviceRegime.$error}">
              <label class="form-label">91 - Regime de atendimento</label>
              <select id="referral-service-type" class="form-select"
                      v-model="form.serviceRegime"
                      @blur="$v.form.serviceRegime.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in serviceRegimes"
                        :value="value" :key="value">{{ text }}</option>
              </select>
              <template v-if="$v.form.serviceRegime.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.serviceRegime.required">
                  Campo obrigatório
                </div>
              </template>
            </div>
            <div class="column col-3 form-group">
              <label class="form-label">92 - Saúde Ocupacional</label>
              <select id="referral-service-type" class="form-select"
                      v-model="form.occupationalHealth">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in occupationalHealth"
                        :value="value" :key="value">{{ text }}</option>
              </select>
            </div>
          </div>
          <expense-items
            ref="expenseItems"
            origin="sadt"
            :source="source"
            :professional-id="data.professionalId"
            :specialty-code="data.specialtyCode"
            :expenses="form.expenses"
            :general-rules="planSettings.general"
            :insurance-plan-id="form.patient.insurance.plan.id"
          />
          <div>
            <label class="form-label">58 - Observação/Justificativa</label>
            <textarea class="form-input" rows="2"
                      v-model="form.justification"
            />
          </div>
        </template>
      </div>
      <template slot="footer">
        <button class="btn btn-primary float-left"
                v-if="isGroupSession && !finished"
                @click="modalPrevious.show = true">
          Sessões anteriores
        </button>
        <button class="btn btn-primary mr-1" :class="{loading: saving}"
                v-if="!finished"
                @click="save" :disabled="saving || loading">
          Finalizar
        </button>
        <button class="btn"
                @click="close(false)">Sair</button>
      </template>
    </dx-modal>
    <modal-previous
      v-if="modalPrevious.show"
      :show="modalPrevious.show"
      :patient-id="form.patient.id"
      :insurance-plan-id="form.patient.insurance.plan.id"
      :loading-referral="modalPrevious.loading"
      @selected="previousSelected"
      @close="modalPrevious.show = false"
    />
    <detail-modal
      id="modal-detail"
      v-if="modalDetail.show"
      :show="modalDetail.show"
      :transactionId="modalDetail.id"
      @close="closeModalDetail"
    />
  </div>
</template>

<script>
import formMixin from 'src/mixins/form';
import { mergeFrom, deepClone } from 'src/helpers/object';
import { mapActions, mapMutations, mapState } from 'vuex';
import { required } from 'vuelidate/src/validators';
import { date, hour, maxDate } from '@/data/validators';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
import { consultationTypes } from '@/data/consultation-types';
import { accidentIndications } from '@/data/accident-indications';
import { characterServices } from '@/data/character-services';
import { closureReasons } from '@/data/closure-reasons';
import { serviceTypes } from '@/data/service-types';
import { occupationalHealth } from '@/data/occupational-health';
import { serviceRegimes } from '@/data/service-regimes';
import { specialCoverages } from '@/data/special-coverages';
import * as council from 'src/data/council-types';
import states from 'src/data/states';
import moment from 'moment';
import ExpenseItems from '../../../billing/attendance/components/ExpenseItems.vue';
import ModalPrevious from './Previous.vue';
import DetailModal from '../../../billing/transaction/components/Detail.vue';

export default {
  mixins: [formMixin],
  components: {
    DetailModal,
    ExpenseItems,
    ModalPrevious,
  },
  props: {
    source: {
      type: String,
    },
    show: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    data: {
      id: {
        type: String,
      },
      appointmentId: {
        type: String,
      },
      professionalId: {
        type: String,
      },
      specialtyCode: {
        type: String,
      },
      invoiceId: {
        type: String,
      },
      key: {
        type: String,
      },
      invoice: {
        id: {
          type: String,
        },
        code: {
          type: String,
        },
      },
      startDate: {
        type: String,
      },
      endDate: {
        type: String,
      },
      appointment: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        professional: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        },
        date: {
          type: Date,
        },
      },
      status: {
        type: String,
      },
      referral: {
        type: {
          type: String,
          default: 'not_accident',
        },
        provider: {
          type: String,
        },
        insurance: {
          type: String,
        },
        main: {
          type: String,
        },
      },
      authorization: {
        date: {
          type: String,
        },
        password: {
          type: String,
        },
        validity: {
          type: String,
        },
      },
      request: {
        date: {
          type: String,
        },
        professional: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
          council: {
            name: {
              type: String,
            },
            state: {
              type: String,
            },
            record: {
              type: String,
            },
          },
          specialty: {
            code: {
              type: String,
            },
            name: {
              type: String,
            },
          },
        },
      },
      patient: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        socialName: {
          type: String,
        },
        birthDate: {
          type: Date,
        },
        gender: {
          type: Date,
        },
        insurance: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
          type: {
            type: String,
          },
          plan: {
            id: {
              type: String,
            },
            name: {
              type: String,
            },
            settings: {
              tiss: {
                enabled: {
                  type: Boolean,
                },
                consultationInSadt: {
                  type: Boolean,
                },
              },
              record: {
                required: {
                  type: Boolean,
                },
                requiredValidity: {
                  type: Boolean,
                },
                digits: {
                  enabled: {
                    type: Boolean,
                  },
                  min: {
                    type: Number,
                  },
                  max: {
                    type: Number,
                  },
                },
                fillValidityWithServiceDate: {
                  type: Boolean,
                },
              },
            },
          },
          insured: {
            record: {
              type: String,
            },
            name: {
              type: String,
            },
            validity: {
              type: Date,
            },
          },
        },
        cns: {
          type: String,
        },
        isNewborn: {
          type: Boolean,
          default: false,
        },
      },
      serviceType: {
        type: String,
      },
      consultationType: {
        type: String,
      },
      character: {
        type: String,
      },
      accidentIndication: {
        type: String,
        default: 'not_accident',
      },
      justification: {
        type: String,
      },
      clinicalIndication: {
        type: String,
      },
      closureReason: {
        type: String,
      },
      occupationalHealth: {
        type: String,
      },
      serviceRegime: {
        type: String,
      },
      specialCoverage: {
        type: String,
      },
      cashier: {
        type: Object,
      },
      installments: {
        type: Array,
      },
      expenses: {
        type: Array,
      },
      total: {
        type: Number,
      },
      courtesy: {
        type: Boolean,
      },
      paymentDate: {
        type: Date,
      },
    },
  },
  data() {
    return {
      path: '/billing-accounts',
      loading: false,
      loadingRequester: false,
      saving: false,
      consultationTypes,
      accidentIndications,
      characterServices,
      closureReasons,
      serviceTypes,
      council,
      states,
      occupationalHealth,
      serviceRegimes,
      specialCoverages,
      isCurrentInvoice: false,
      professionals: [],
      invoiceCodes: [],
      requestSpecialty: null,
      startDate: '',
      startHour: '',
      planSettings: this.blankPlanSettingsForm(),
      form: this.blankForm(),
      modalPrevious: {
        show: false,
        loading: false,
      },
      modalDetail: {
        show: false,
        id: '',
      },
    };
  },
  validations() {
    const roles = {
      startDate: { required, date },
      startHour: { required, hour },
      form: {
        patient: {
          insurance: {
            insured: {
              record: { required, minLength: minLength(1), maxLength: maxLength(20) },
              name: { required },
              validity: { date },
            },
          },
        },
        referral: {
          type: { required },
          provider: {},
          insurance: {},
          main: {},
        },
        authorization: {
          date: { date },
          password: {},
          validity: { date },
        },
        request: {
          date: { date },
          professional: {
            name: { required },
            council: {
              name: { required },
              state: { required },
              record: { required },
            },
          },
        },
        serviceType: { required },
        consultationType: {},
        character: { required },
        serviceRegime: { required },
        accidentIndication: { required },
        clinicalIndication: {},
      },
      requestSpecialty: { required },
    };

    if (this.form.serviceType === 'consultation') {
      roles.form.consultationType = { required };
    }

    if (this.planSettings.record.required) {
      roles.form.patient.insurance.insured.record = {
        required,
        minLength: minLength(1),
        maxLength: maxLength(20),
      };
      roles.form.patient.insurance.insured.name = {
        required,
      };
    }

    if (this.planSettings.record.requiredValidity) {
      roles.form.patient.insurance.insured.validity = {
        required, date,
      };
    }

    if (this.planSettings.record.digits.enabled) {
      roles.form.patient.insurance.insured.record = {
        required,
        minLength: minLength(this.planSettings.record.digits.min),
        maxLength: maxLength(this.planSettings.record.digits.max),
      };
    }

    // Solicitação
    if (this.planSettings.sadt.requiredRequestDate && this.form.consultationType !== 'return') {
      roles.form.request.date = { required, date };
    }

    if (this.form.request.date) {
      roles.form.request.date = {
        required,
        date,
        minDate: maxDate(moment(this.startDate).format('YYYY-MM-DD')),
      };
    }

    // Autorização
    if (this.form.consultationType !== 'return') {
      if (this.planSettings.sadt.authorization.requiredDate) {
        roles.form.authorization.date = { required, date };
      }
      if (this.planSettings.sadt.authorization.requiredPassword) {
        roles.form.authorization.password = { required };
      }
      if (this.planSettings.sadt.authorization.requiredMainReferral) {
        roles.form.referral.main = { required };
      }
      if (this.planSettings.sadt.authorization.requiredValidity) {
        roles.form.authorization.validity = { required, date };
      }
      if (this.planSettings.sadt.authorization.requiredInsuranceReferral) {
        roles.form.referral.insurance = { required };
      }
    }
    if (this.form.authorization.date) {
      roles.form.authorization.date = {
        required,
        date,
      };
    }
    if (this.form.authorization.validity) {
      roles.form.authorization.validity = {
        required,
        date,
      };
    }
    if (this.form.authorization.date && this.form.authorization.validity) {
      roles.form.authorization.validity = {
        required,
        date,
      };
    }

    if (this.planSettings.referral.requireClinicalIndication === true
      && this.form.consultationType !== 'return') {
      roles.form.clinicalIndication = { required };
    }

    if (this.planSettings.providerReferral.mode === 'insurance_referral') {
      roles.form.referral.insurance = {
        required,
      };
    } else {
      roles.form.referral.provider = {
        required,
      };
    }

    return roles;
  },
  async mounted() {
    this.loading = true;
    await this.loadProfessionals();
    this.init();
    await this.loadInvoiceCodes();
    await this.loadPlanSettings();
    this.setProfessionalRequest();
    this.loading = false;
    this.$v.form.$touch();
    this.$v.requestSpecialty.$touch();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      appointment: state => state.appointment.form,
    }),
    finished() {
      return ['finished', 'closed'].includes(this.form.status);
    },
    hasInvoice() {
      return this.invoiceCodes.some(({ id }) => id === this.form.invoiceId);
    },
    hasRequesterCouncil() {
      return !(this.form.request.professional.council.state
        && this.form.request.professional.council.name);
    },
    isGroupSession() {
      return !this.planSettings.general.billing.billingForIndividualSessions
        && ['other_therapies', 'small_service'].includes(this.form.serviceType);
    },
  },
  methods: {
    ...mapMutations({
      setAccount: 'Appointment.SET_ACCOUNT',
    }),
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    init() {
      this.form = mergeFrom(this.blankForm(), deepClone(this.data));
      this.startDate = moment(this.form.startDate).format('YYYY-MM-DD');
      this.startHour = moment(this.form.startDate).format('HH-mm');

      if (this.source === 'appointments' && !this.form.id) {
        const [expense] = this.form.expenses;
        if (['consultation', 'exam'].includes(expense.type)) {
          this.form.serviceType = expense.type;
          if (expense.type === 'consultation') {
            this.form.consultationType = expense.return ? 'return' : 'first';
          }
        } else if (['session', 'evaluation'].includes(expense.type)) {
          this.form.serviceType = 'other_therapies';
        } else if (expense.type === 'laboratory') {
          this.form.serviceType = 'exam';
        } else if (expense.type === 'surgery') {
          this.form.serviceType = 'minor_surgery';
        } else {
          this.form.serviceType = 'small_service';
        }
      }

      if (this.form.request.professional.specialty.code) {
        this.requestSpecialty = this.form.request.professional.specialty;
      }
    },
    setProfessionalRequest() {
      if (!this.form.id
        && this.appointment.indicated
        && this.appointment.indicated.type === 'professional'
        && this.appointment.indicated.name) {
        const professional = this.appointment.indicated;
        this.form.request.professional.id = professional.id;
        this.form.request.professional.name = professional.name;
        if (professional.council && professional.council.record) {
          this.form.request.professional.council = professional.council;
        }
        if (professional.specialty && professional.specialty.code) {
          this.form.request.professional.specialty = professional.specialty;
          this.requestSpecialty = professional.specialty;
        }
      }
    },
    close(save = false) {
      this.$emit('close', save ? this.form : null);
    },
    validateItems() {
      return this.$refs.expenseTableItems.every(item => item.validate());
    },
    save() {
      this.$v.startDate.$touch();
      this.$v.startHour.$touch();
      this.$v.requestSpecialty.$touch();
      this.$v.form.$touch();
      if (this.$v.startDate.$error
        || this.$v.startHour.$error
        || this.$v.form.$error
        || this.$v.requestSpecialty.$error
        || this.saving) {
        return null;
      }

      if (this.form.expenses.length === 0) {
        this.$toast.error('Nenhum procedimento adicionado!');
        return null;
      }

      this.saving = true;
      this.form.startDate = `${this.startDate} ${this.startHour}`;
      this.form.endDate = `${this.startDate} ${this.startHour}`;

      const branch = this.user.branch
      && this.user.branch.id
        ? { id: this.user.branch.id, name: this.user.branch.name }
        : null;

      const data = {
        appointmentId: this.form.appointmentId,
        branch,
        status: 'open',
        referral: {
          type: 'sadt',
          insurance: this.form.referral.insurance,
          provider: this.form.referral.provider,
          main: this.form.referral.main,
        },
        serviceType: this.form.serviceType,
        character: this.form.character,
        accidentIndication: this.form.accidentIndication,
        justification: this.form.justification,
        clinicalIndication: this.form.clinicalIndication,
        occupationalHealth: this.form.occupationalHealth,
        serviceRegime: this.form.serviceRegime,
        specialCoverage: this.form.specialCoverage,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        insurance: {
          id: this.form.patient.insurance.id,
          planId: this.form.patient.insurance.plan.id,
          insured: {
            record: this.form.patient.insurance.insured.record,
            name: this.form.patient.insurance.insured.name,
            validity: this.form.patient.insurance.insured.validity,
          },
        },
        patient: {
          id: this.form.patient.id,
          name: this.form.patient.name,
          cns: this.form.patient.cns,
          isNewborn: this.form.patient.isNewborn,
          socialName: this.form.patient.socialName,
        },
        authorization: {
          date: this.form.authorization.date,
          password: this.form.authorization.password,
          validity: this.form.authorization.validity,
        },
        expenses: this.form.expenses.map(expense => ({
          id: expense.id,
          itemId: expense.itemId,
          financialBundleId: expense.financialBundleId,
          startDate: expense.startDate,
          endDate: expense.endDate,
          code: expense.code,
          name: expense.name,
          accountId: expense.accountId,
          quantity: expense.quantity,
          professionalId: expense.professional.id,
          specialtyCode: expense.professional.specialty.code,
          accreditedProfessionalId: expense.professional.accredited
            ? expense.professional.accredited.id : null,
          accreditedSpecialtyCode: expense.professional.accredited
            && expense.professional.accredited.specialty
            ? expense.professional.accredited.specialty.code : null,
          degreeParticipationCode: expense.professional.degreeParticipation,
          way: expense.way ? expense.way : null,
          technique: expense.technique ? expense.technique : null,
          factor: expense.factor,
          value: {
            discount: expense.value.discount || 0,
            interest: expense.value.interest || 0,
            rate: expense.value.rate || 0,
            insurance: expense.value.insurance || 0,
            particular: expense.value.particular || 0,
          },
        })),
      };

      if (this.form.consultationType) {
        data.consultationType = this.form.consultationType;
      }

      if (this.form.closureReason) {
        data.closureReason = this.form.closureReason;
      }

      if (this.form.request.professional.name) {
        data.request = data.request || {};
        if (this.form.request.date) {
          data.request.date = this.form.request.date;
        }
        data.request.professional = {
          name: this.form.request.professional.name,
          council: {
            name: this.form.request.professional.council.name,
            state: this.form.request.professional.council.state,
            record: this.form.request.professional.council.record,
          },
          specialty: {
            code: this.form.request.professional.specialty.code,
            name: this.form.request.professional.specialty.name,
          },
        };
      }

      const isNew = !this.form.id;

      const request = isNew
        ? this.$httpX.post(`${this.path}`, data)
        : this.$httpX.put(`${this.path}/${this.form.id}`, data);

      return request
        .then(({ data: result }) => {
          this.form.id = result.id;

          result.expenses.forEach((item) => {
            const found = this.$store.state.appointment.form.expenses
              .find(({ id }) => id === item.expense.id);
            if (found) {
              found.return = item.return || false;
              found.value.discount = item.value.discount;
              found.value.feeValue = item.value.fee;
              found.value.filmValue = item.value.film;
              found.value.insurance = item.value.insurance;
              found.value.interest = item.value.interest;
              found.value.operationalCostValue = item.value.operationalCost;
              found.value.particular = item.value.particular;
              found.value.rate = item.value.rate;
              found.value.tax = item.value.tax;
              if (!found.accountId) {
                found.accountId = result.id;
                found.selected = false;
              }
            }
          });

          this.setAccount({
            key: this.data.key,
            expenses: this.$store.state.appointment.form.expenses,
          });

          this.$toast.show('Salvo com sucesso');
          this.close(true);
        })
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.show(e.response.data.message, { type: 'error', timeout: 5000 });
          }
        })
        .then(() => {
          this.saving = false;
        });
    },
    openTransaction(id) {
      this.modalDetail.id = id;
      this.modalDetail.show = true;
    },
    clearConsultation() {
      if (this.form.serviceType !== 'consultation') {
        this.form.consultationType = '';
      }
    },
    async closeModalDetail(save) {
      if (save) {
        this.$http.get(`/financial-transactions/${this.modalDetail.id}`)
          .catch((e) => {
            if (e.response && e.response.status === 404) {
              const items = this.form.expenses
                .filter(({ financialBundleId }) => financialBundleId === this.modalDetail.id);
              items.forEach((item) => {
                item.financialBundleId = null;
                return item;
              });
            }
          });
      }
      this.modalDetail.show = false;
    },
    changeInsuranceReferral() {
      this.fillProviderReferralWithInsuranceReferral();
      this.fillPasswordInsuranceReferral('insurance_referral');
    },
    changePassword() {
      this.fillProviderReferralWithPassword();
      this.fillPasswordInsuranceReferral('password');
    },
    fillInsuredValidityWithServiceDate() {
      if (this.planSettings.record.fillValidityWithServiceDate) {
        this.form.patient.insurance.insured.validity = moment(this.startDate)
          .format('YYYY-MM-DD');
      }
    },
    fillInsuredNameWithPatientName() {
      if (!this.form.patient.insurance.insured.name) {
        this.form.patient.insurance.insured.name = this.form.patient.name;
      }
    },
    fillProviderReferralWithPassword() {
      if (this.planSettings.providerReferral.mode === 'password') {
        this.form.referral.provider = this.form.authorization.password;
      }
    },
    fillProviderReferralWithInsuranceReferral() {
      if (this.planSettings.providerReferral.mode === 'insurance_referral') {
        this.form.referral.provider = this.form.referral.insurance;
      }
    },
    fillPasswordInsuranceReferral(originField) {
      if (this.planSettings.sadt.authorization.fillInsuranceReferralWithPassword) {
        if (originField === 'password') {
          this.form.referral.insurance = this.form.authorization.password;
        }
        if (originField === 'insurance_referral') {
          this.form.authorization.password = this.form.referral.insurance;
        }
      }
    },
    fillAuthorizationDateWithServiceDate() {
      if (this.planSettings.sadt.fillAuthorizationDateWithServiceDate) {
        if (!this.form.authorization.date && this.startDate) {
          this.form.authorization.date = moment(this.startDate).format('YYYY-MM-DD');
        }
      }
    },
    fillAuthorizationValidity() {
      if (this.form.authorization.date) {
        if (this.planSettings.sadt.authorization.fillValidityDate.enabled) {
          if (!this.form.authorization.validity) {
            const { days } = this.planSettings.sadt.authorization.fillValidityDate;
            const authorizationDate = this.form.authorization.date;
            this.form.authorization.validity = moment(authorizationDate)
              .add(days, 'day')
              .format('YYYY-MM-DD');
          }
        }
      }
    },
    fillRequestDataWithServiceDate() {
      if (this.planSettings.sadt.fillRequestDataWithServiceDate) {
        if (!this.form.request.date) {
          this.form.request.date = moment(this.startDate).format('YYYY-MM-DD');
        }
      }
    },
    focusAuthorizationDate() {
      this.fillAuthorizationDateWithServiceDate();
    },
    focusAuthorizationValidity() {
      this.fillAuthorizationValidity();
    },
    focusRequestDate() {
      this.fillRequestDataWithServiceDate();
    },
    getProfessionalExecutantData() {
      if (this.form.expenses && this.form.expenses.length === 0) {
        this.$toast.error('Nenhum procedimento adicionado!');
        return;
      }

      const data = this.form.expenses[0].professional;

      if (!data || !data.id) {
        this.$toast.error('Informe o profissional executante!');
        return;
      }

      const professionalId = data.accredited && data.accredited.id ? data.accredited.id : data.id;
      const professional = this.professionals.find(item => item.id === professionalId);

      if (professional) {
        this.form.request.professional = {
          id: professional.id,
          name: professional.name,
          council: null,
          specialty: null,
        };
        if (professional.council) {
          this.form.request.professional.council = {
            name: professional.council.name,
            state: professional.council.state,
            record: professional.council.record,
          };
        }
        if (professional.specialty) {
          this.requestSpecialty = professional.specialty;
          this.form.request.professional.specialty = {
            code: professional.specialty.code,
            name: professional.specialty.name,
          };
        }
      } else {
        this.$toast.error('Verifique o profissional na lista de procedimentos!');
      }
    },
    findProfessionalRequesterByCouncil() {
      if (!this.form.request.professional.council.name
        || !this.form.request.professional.council.state
        || !this.form.request.professional.council.record) {
        return null;
      }

      this.loadingRequester = true;

      const params = {
        councilName: this.form.request.professional.council.name,
        councilState: this.form.request.professional.council.state,
        councilRecord: this.form.request.professional.council.record,
      };

      return this.$http.get('/professional-requesters', { params })
        .then(({ data }) => {
          if (data) {
            this.form.request.professional.id = data.id;
            this.form.request.professional.name = data.name;
            if (data.specialty) {
              this.form.request.professional.specialty = data.specialty;
              this.requestSpecialty = data.specialty;
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loadingRequester = false;
        });
    },
    addCbo(cbo) {
      if (!cbo) {
        return;
      }
      this.form.request.professional.specialty = {
        code: cbo.code,
        name: cbo.name,
      };
    },
    findCbo(search) {
      const params = {
        search,
        isSpecialty: true,
      };

      return this.$http.get('/services/cbos', { params })
        .then(({ data }) => data.items);
    },
    unsetCbo() {
      this.requestSpecialty = null;
      this.form.request.professional.specialty = {};
    },
    loadInvoiceCodes() {
      const params = {
        insurancePlanId: this.data.patient.insurance.plan.id,
      };
      return this.$http.get('/billing-invoice-codes', { params })
        .then(({ data }) => {
          this.invoiceCodes = data.items;
          const found = this.invoiceCodes.find(({ status }) => status === 'open');
          this.isCurrentInvoice = !!found;
          if (!this.form.invoiceId && this.isCurrentInvoice) {
            if (found) {
              this.form.invoiceId = found.id;
            } else {
              this.form.invoiceId = this.invoiceCodes[0].id;
            }
          }
        })
        .catch(() => {});
    },
    loadProfessionals() {
      const councilsToFilter = [
        'COREN',
        'CREF',
        'CRFA',
        'CRM',
        'CRO',
        'CREFITO',
        'CRN',
        'CRP',
        'CREFONO',
      ];
      return this.apiFetchProfessionals()
        .then((items) => {
          items.forEach((item) => {
            const councils = item.professional.councils.map(councilItem => councilItem.name);
            if (councils && councils.length > 0) {
              const filtered = councils.filter(el => councilsToFilter.includes(el));
              if (filtered.length > 0) {
                item.professional.specialties.forEach((specialty) => {
                  this.professionals.push({
                    id: item.id,
                    name: item.name,
                    council: item.professional.councils.length > 0
                      ? item.professional.councils[0]
                      : { state: '', record: '', name: '' },
                    specialty,
                  });
                });
              }
            }
          });
          let filtered = this.professionals.filter(item => item.id === this.form.professionalId);
          if (filtered.length === 0) {
            this.form.professionalId = '';
          }
          this.form.expenses.forEach((expense) => {
            filtered = this.professionals.filter(item => item.id === expense.professional.id);
            if (filtered.length === 0) {
              expense.professional.id = '';
            }
          });
        })
        .catch(() => {});
    },
    addedExpense(expense, accountId) {
      if (!expense) {
        return;
      }
      expense.accountId = accountId;
      this.form.expenses.push(expense);
      this.$refs.expenseItems.totalExpenses();
    },
    async previousSelected(accountId) {
      if (this.modalPrevious.loading) {
        return;
      }

      this.modalPrevious.loading = true;

      const insurancePlanId = this.form.patient.insurance.plan.id;

      let account;
      await this.$http.get(`/billing-accounts/${accountId}`)
        .then(({ data }) => {
          const professional = data.expenses.length > 0
            ? data.expenses[0].professional
            : null;
          if (data.invoice && data.invoice.id) {
            data.invoiceId = data.invoice.id;
          }
          data.professionalId = professional.id;
          data.specialtyCode = professional.specialty
            ? professional.specialty.code
            : null;
          // data.key = this.form.key;
          data.expenses.forEach((expense) => {
            expense.accountId = accountId;
            expense.execution = {
              date: moment(expense.startDate).format('YYYY-MM-DD'),
              time: moment(expense.startDate).format('HH:mm'),
              duration: moment.duration(moment(expense.endDate)
                .diff(moment(expense.startDate))).asMinutes(),
            };
            expense.rules = {
              requireAuthorization: false,
              requireClinicalIndication: false,
              requireWayTechnique: false,
            };
          });
          account = data;
        });

      const params = {
        expenseIds: account.expenses.map(({ id }) => id).join(','),
      };
      await this.$http.get(`/expense-insurances/${insurancePlanId}/rules`, { params })
        .then(({ data }) => {
          if (data.items) {
            if (data.items.length > 0) {
              data.items.forEach((item) => {
                account.expenses.forEach((expense) => {
                  if (item.id === expense.id) {
                    if (item.rules) {
                      expense.rules = item.rules;
                    }
                  }
                });
              });
            }
          }
        });
      const expense = this.form.expenses.length > 0 ? this.form.expenses[0] : null;
      delete expense.financialBundleId;
      delete expense.itemId;
      this.form = mergeFrom(this.blankForm(), deepClone(account));
      this.form.appointmentId = this.data.appointmentId;
      this.startDate = moment(this.form.startDate).format('YYYY-MM-DD');
      this.startHour = moment(this.form.startDate).format('HH-mm');
      this.requestSpecialty = this.form.request.professional.specialty;
      this.addedExpense(expense, accountId);
      this.modalPrevious.loading = false;
      this.modalPrevious.show = false;
    },
    async loadPlanSettings() {
      const insuranceId = this.form.patient.insurance.id;
      const planId = this.form.patient.insurance.plan.id;
      const params = `/insurances/${insuranceId}/plans/${planId}`;

      if (this.form.patient.insurance.plan.id) {
        await this.$http.get(params)
          .then(({ data }) => {
            if (data.settings.tiss.sadt) {
              this.planSettings.sadt.requiredRequestDate = data
                .settings.tiss.sadt.requiredRequestDate;
              this.planSettings.sadt.fillAuthorizationDateWithServiceDate = data
                .settings.tiss.sadt.fillAuthorizationDateWithServiceDate;
              this.planSettings.sadt.fillRequestDataWithServiceDate = data
                .settings.tiss.sadt.fillRequestDataWithServiceDate;
              this.planSettings.sadt.authorization.fillInsuranceReferralWithPassword = data
                .settings.tiss.sadt.authorization.fillInsuranceReferralWithPassword;
              this.planSettings.sadt.authorization.fillValidityDate.enabled = data
                .settings.tiss.sadt.authorization.fillValidityDate.enabled;
              this.planSettings.sadt.authorization.fillValidityDate.days = data
                .settings.tiss.sadt.authorization.fillValidityDate.days;
              this.planSettings.sadt.authorization.requiredDate = data
                .settings.tiss.sadt.authorization.requiredDate;
              this.planSettings.sadt.authorization.requiredInsuranceReferral = data
                .settings.tiss.sadt.authorization.requiredInsuranceReferral;
              this.planSettings.sadt.authorization.requiredMainReferral = data
                .settings.tiss.sadt.authorization.requiredMainReferral;
              this.planSettings.sadt.authorization.requiredPassword = data
                .settings.tiss.sadt.authorization.requiredPassword;
              this.planSettings.sadt.authorization.requiredValidity = data
                .settings.tiss.sadt.authorization.requiredValidity;
            }

            if (data.settings.general.providerReferral) {
              this.planSettings.providerReferral.mode = data.settings.general.providerReferral.mode;
            }
            if (data.settings.general.record) {
              this.planSettings.record.required = data.settings.general.record.required;
              this.planSettings.record.requiredValidity = data
                .settings.general.record.requiredValidity;
              this.planSettings.record.fillValidityWithServiceDate = data
                .settings.general.record.fillValidityWithServiceDate;
              this.planSettings.record.digits.enabled = data.settings.general.record.digits.enabled;
              this.planSettings.record.digits.min = data.settings.general.record.digits.min;
              this.planSettings.record.digits.max = data.settings.general.record.digits.max;
            }
            if (data.settings.general.billing) {
              if (data.settings.general.billing.blockValues) {
                this.planSettings.general.billing.blockValues = data.settings
                  .general.billing.blockValues;
              }
              if (data.settings.general.billing.hideInsuranceValue) {
                this.planSettings.general.billing.hideInsuranceValue = data.settings
                  .general.billing.hideInsuranceValue;
              }
              if (data.settings.general.billing.billingForIndividualSessions) {
                this.planSettings.general.billing.billingForIndividualSessions = data.settings
                  .general.billing.billingForIndividualSessions;
              }
            }
          })
          .catch(() => {
          });
      }

      this.form.expenses.forEach((expense) => {
        if (expense.rules) {
          if (expense.rules.requireAuthorization === true) {
            this.planSettings.referral.requireAuthorization = true;
          }
          if (expense.rules.requireClinicalIndication === true) {
            this.planSettings.referral.requireClinicalIndication = true;
          }
        }
      });

      this.loadPlanSettingsParams();
    },
    loadPlanSettingsParams() {
      this.fillInsuredNameWithPatientName();
      this.fillInsuredValidityWithServiceDate();
      this.fillAuthorizationDateWithServiceDate();
      this.fillAuthorizationValidity();
      this.fillRequestDataWithServiceDate();
      if (this.planSettings.providerReferral.mode === 'automatic') {
        if (!this.form.referral.provider) {
          this.form.referral.provider = 'GUIA_AUTOMATICA';
        }
      }
    },
    blankPlanSettingsForm() {
      return {
        referral: {
          requireAuthorization: false,
          requireClinicalIndication: false,
        },
        sadt: {
          requiredRequestDate: false,
          fillAuthorizationDateWithServiceDate: false,
          fillRequestDataWithServiceDate: false,
          authorization: {
            fillInsuranceReferralWithPassword: false,
            fillValidityDate: {
              enabled: false,
              days: 0,
            },
            requiredDate: true,
            requiredInsuranceReferral: false,
            requiredMainReferral: false,
            requiredPassword: true,
            requiredValidity: true,
          },
        },
        providerReferral: {
          mode: 'manual',
        },
        record: {
          digits: {
            enabled: false,
            min: 0,
            max: 0,
          },
          fillValidityWithServiceDate: false,
          required: false,
          requiredValidity: false,
        },
        general: {
          billing: {
            blockValues: false,
            hideInsuranceValue: false,
            billingForIndividualSessions: false,
          },
        },
      };
    },
    blankForm() {
      return {
        id: '',
        key: '',
        status: '',
        startDate: '',
        endDate: '',
        appointmentId: '',
        professionalId: '',
        specialtyCode: '',
        invoiceId: '',
        invoice: {
          id: '',
          code: '',
        },
        referral: {
          type: 'sadt',
          provider: '',
          insurance: '',
          main: '',
        },
        patient: {
          id: '',
          name: '',
          socialName: '',
          birthDate: '',
          gender: '',
          isNewborn: false,
          insurance: {
            id: '',
            name: '',
            type: '',
            plan: {
              id: '',
              name: '',
              settings: {
                tiss: {
                  enabled: false,
                  consultationInSadt: false,
                },
                record: {
                  required: false,
                  requiredValidity: false,
                  digits: {
                    enabled: false,
                    min: 0,
                    max: 0,
                  },
                  fillValidityWithServiceDate: false,
                },
              },
            },
            insured: {
              record: '',
              name: '',
              validity: '',
            },
          },
          cns: '',
        },
        authorization: {
          date: null,
          password: '',
          validity: null,
        },
        request: {
          date: '',
          professional: {
            id: '',
            name: '',
            council: {
              name: '',
              state: '',
              record: '',
            },
            specialty: {
              code: '',
              name: '',
            },
          },
        },
        serviceType: '',
        consultationType: '',
        character: 'elective',
        accidentIndication: 'not_accident',
        justification: '',
        clinicalIndication: '',
        closureReason: '',
        occupationalHealth: '',
        serviceRegime: 'outpatient',
        specialCoverage: '',
        installments: [],
        expenses: [],
        total: 0,
        courtesy: false,
        paymentDate: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
.insurance-account {
}
</style>
